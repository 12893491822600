import React from 'react';
import { Box } from '@mui/material';
import './App.css';
import Routes from './Routes';
import TopBar from './components/TopBar';
import AppSnack from './components/AppSnack';

function App() {
  return (
    <Box
      sx={{
        height: '100vh',
        backgroundSize: 'auto 100%',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f9f9f9'
      }}
    >
      <TopBar />
      <Routes />
      {/* <AppSnack /> */}
      {/* <p>{JSON.stringify(st, null, 2) }</p> */}
    </Box>
  );
}

export default App;
