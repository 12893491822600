import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import store from './store/store';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#f50057',
    },
    action: {
      selected: '#E7A615',
      hover: '#000000',
      disabled: 'grey',
    },
  },
  components: {
    MuiLink: {
      focusVisible: {
        backgroundColor: 'yelow',
      },
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: '14px',
          textDecoration: 'nne',
        },
      },
    },
    // Name of the component
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
          transition: 'background 0.3s, color 0.5s',
          '&:hover': {
            color: 'white',
            backgroundColor: '#7D7D7D',
            boxShadow:
              '0 12px 20px -10px rgb( 76 140 74 / 28%), 0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(76 140 74 / 20%)',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          '& .MuiTouchRipple-root': {
            color: '#7D7D7D',
          },
          fontSize: '1re',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            fontWeight: '60',
          },
        },
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
