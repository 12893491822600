import { combineReducers } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import countReducer from '../features/counter/counterSlice';
import dataReducer from '../features/data/dataSlice';
import imageReducer from '../features/data/imageDataSlice';
import onboardingAPI from '../services';

const rootReducer = combineReducers({
  auth: authReducer,
  data: dataReducer,
  imageData: imageReducer,
  count: countReducer,
  [onboardingAPI.reducerPath]: onboardingAPI.reducer,
  // [coreAPI.reducerPath]: coreAPI.reducer
});

export default rootReducer;
