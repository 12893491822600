import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import onboardingAPI from '../services';
import { setError } from '../features/data/dataSlice';

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    let error = 'We got a rejected action';
    if (action?.payload?.data?.title) {
      error = `${action?.payload?.status} ${action?.payload?.error}`;
    }
    if (action?.payload?.status) {
      error = `${action?.payload?.status} ${action?.payload?.error}`;
    }
    if (action?.meta?.baseQueryMeta?.response?.status) {
      error = `${action?.meta?.baseQueryMeta?.response?.status} - ${action?.meta?.baseQueryMeta?.response?.statusText}`;
    }
    api.dispatch(setError(error));
    // toast.warn({ title: 'Async error!', message: action.error.data.message });
  }

  return next(action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([onboardingAPI.middleware, rtkQueryErrorLogger]), // coreAPI.middleware
});

export default store;
