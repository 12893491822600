import { createSlice } from '@reduxjs/toolkit';
import onboardingAPI from '../../services';

const initialState = {
  // selectedVendor: null,
  error: null,
  imageUploadResponse: null,
  // uploads: {
  //   Lookbook: [],
  //   Pricat: [],
  // },
};

const data = createSlice({
  name: 'imageData',
  initialState,
  reducers: {
    patchImageUploadResponse(state, { payload }) {
      state.imageUploadResponse = payload;
    },
    setError(state, { payload }) {
      state.error = payload;
    },
    clearError(state) {
      state.error = null;
    },
    // setSelectedVendor(state, { payload }) {
    //   state.selectedVendor = payload;
    // },
    // setPricatUploadResponse(state, { payload }) {
    //   state.imageUploadResponse = payload;
    // },
    // setUploads(state, { payload }) {
    //   state.uploads = payload;
    // },
  },
  extraReducers: (builder) => {
    builder .addMatcher(onboardingAPI.endpoints.uploadImage.matchFulfilled, (state, { payload }) => {
        state.imageUploadResponse = payload;
    });
},

});

export const {
  patchImageUploadResponse,
  setError,
  clearError,
  // setSelectedVendor, setPricatUploadResponse, updatePricatItem, setUploads
} = data.actions;

export default data.reducer;
