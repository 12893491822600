import React from 'react';
import { AppBar, Box, Toolbar, Link, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonIcon from '@mui/icons-material/Person';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import StoreIcon from '@mui/icons-material/Store';
import Divider from '@mui/material/Divider';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import onboardingAPI from '../services';
import { clearToken } from '../features/auth/authSlice';
import backGroundImage from '../assets/img/royallogo.svg';

const StyledToolbar = styled(Toolbar)(() => ({
  backgroundPosition: 'center',
  height: '100px',
}));

const TopBar = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { data: userData, error } = onboardingAPI.useGetUserQuery(undefined, {
    skip: !token,
  });
  // const { selectedVendor } = useSelector((state) => state.data);
  const { data: selectedVendor } = onboardingAPI.useGetVendorInfoQuery(userData?.activeVendor, {
    skip: !userData?.activeVendor,
  });

  
  const [setSelectedVendor] = onboardingAPI.useSetSelectedVendorMutation({
    fixedCacheKey: 'set-vendor-fixed-key',
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // useEffect(() => {
  //   if (data?.activeVendor) {
  //     dispatch(setSelectedVendor(data?.activeVendor));
  //   }
  // }, [data, dispatch]);

  const login = () => {
    if (!token) {
      window.location = process.env.REACT_APP_LOGIN_URL;
    }
  };

  const logout = () => {
    dispatch(clearToken());
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItemClickHandler = (vendor) => {
    if (vendor?.id !== selectedVendor?.id) {
      setSelectedVendor(vendor.id);
    }
    handleClose();
  };

  if (error?.status === 401) {
    dispatch(clearToken());
  }
  return (
    <Box>
      <AppBar position="static" sx={{ backgroundColor: '#FFFFFF' }}>
      <StyledToolbar >
      <Grid container justifyContent="flex-start">
        <Link href="/" sx={{ justifyContent: 'flex-start' }}>
          <Box
            component="img"
            sx={{ height: 30 }}
            alt="Logo"
            src={backGroundImage}
          />
        </Link>
        </Grid>
          {token ? (
            <Grid container justifyContent="flex-end">
            <Box >
              <Button
              startIcon={<HomeRepairServiceIcon />}
              sx={{
                  zIndex: 1,                  
                  marginRight:'40px'
                }}>
                  {selectedVendor?.name}
              </Button>
              <Button
                sx={{
                  zIndex: 1
                }}
                onClick={handleClick}
                color="primary"
                endIcon={<KeyboardArrowDownIcon />}
                startIcon={<PersonIcon />}
              >
                {userData?.name}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open || false}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {userData?.vendors?.map((vendor) => (
                  <Box key={vendor.id} mx={1}>
                    <MenuItem
                      onClick={() => {
                        menuItemClickHandler(vendor);
                      }}
                      disabled={vendor?.id === selectedVendor?.key}
                      disableRipple
                    >
                      <StoreIcon sx={{ mr: 1 }} />
                      {vendor.name}
                    </MenuItem>
                  </Box>
                ))}
                {userData?.vendors?.length && (
                  <Box mx={1}>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem onClick={logout} disableRipple>
                      <LogoutIcon />
                      Log Out
                    </MenuItem>
                  </Box>
                )}
              </Menu>
            </Box>
            </Grid>
          ) : (
            <Grid container justifyContent="flex-end">
            <Button onClick={login} color="primary" endIcon={<LoginIcon />}>
              Log In
            </Button>
            </Grid>
          )}
        </StyledToolbar>
      </AppBar>
    </Box>
  );
};

export default TopBar;
